<style scoped xmlns="http://www.w3.org/1999/html">
  #lead{
    background-image: url("/images/fons-header-2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 250px;
  }
  @media (min-width: 1200px) {
    .bd-sidebar {
      -ms-flex: 0 1 320px;
      flex: 0 1 320px;
    }
  }
  @media (min-width: 768px) {
    @supports ((position:-webkit-sticky) or (position:sticky)){
      .bd-sidebar {
        position: -webkit-sticky;
        position: sticky;
        top: 0rem;
        z-index: 1000;
        height: calc(100vh - 4rem);
      }
    }
    .bd-sidebar {
      border-right: 1px solid rgba(0,0,0,.1);
    }
  }
  .bd-sidebar {
    -ms-flex-order: 0;
    order: 0;
    /*border-bottom: 1px solid rgba(0,0,0,.1);*/
    color: #173c83;
  }

  .pieces{
    width: 5px;
    border-color: #173c83;
    height: 100%;
    min-width: 10px;

  }

  .grey-piece{
    background-color:  gray;
  }
  .blue-piece{
    background-color:  #173c83;
  }


</style>

<style>
.btn input[type="radio" i]{
  display: none !important;
}
</style>

<template>
  <div>
    <div id="lead">
      <b-container style="min-height: 250px">
        <b-row style="min-height: 250px" class="h-100 pt-2 pb-3 align-items-center">
          <b-col cols="12" class="text-white text-center">
            <h2>Bioremediation <b>Technologies</b> </h2>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <b-row class="mt-2">
        <b-form-group
          id="fieldset-1"

          label="Find the technology"
          label-for="input-1"
        >
          <b-form-input id="input-1" v-model="filterSelection.name" trim placeholder="Type the name here..."></b-form-input>
        </b-form-group>
      </b-row>
      <b-row class="flex-xl-nowrap mt-3 mb-3">
        <b-col cols="12" style="display: grid; grid-gap: 10px ; grid-template-columns: repeat(3, 1fr); grid-auto-rows: 1fr ">

            <div v-for="tech in filteredTechnologies" cols="4" class="p-2 border" >
              <b-row><b-col><h4 class="bold pt-3">{{tech.Name}}</h4></b-col></b-row>
              <b-row>
                <b-col>
                  <b-row style="height: 250px"><b-col>
                    <b-img-lazy v-if="tech['CODI IMATGE']" center responsive height="220" width="auto" :src="'/figures/'+tech['CODI IMATGE']"></b-img-lazy>
                    <b-img-lazy v-else center style="max-height: 200px" fluid :src="'/images/ezgif.com-gif-maker.jpeg'"></b-img-lazy>
                  </b-col></b-row>
                  <b-row class="mt-0">
                    <b-col style="display: grid; justify-content: end; align-content: end">
                      <a class="text-decoration-none text-white blue-piece p-2 mt-3" target="_blank" :href="'/fitxes_tech/'+tech['fitxes']">
                        Technical sheet
                        <b-icon-download ></b-icon-download>
                      </a>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>

            </div>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  mounted() {
    this.getData()
  },
  methods:{
    getData(){
      let url = '/DB_ELECTRA_V4.xlsx'
      let oReq = new XMLHttpRequest()
      let _this = this
      oReq.open("GET", url, true)
      oReq.responseType = "arraybuffer"
      oReq.onload = function (e) {
        let arraybuffer = oReq.response;

        /* convert data to binary string */
        let data = new Uint8Array(arraybuffer);
        let arr = new Array();
        for(let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join("");

        /* Call XLSX */
        var workbook = XLSX.read(bstr, {type:"binary"});
        /* DO SOMETHING WITH workbook HERE */
        var first_sheet_name = workbook.SheetNames[0];
        /* Get worksheet */
        var worksheet = workbook.Sheets[first_sheet_name];
        _this.technologies = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      }
      oReq.send();
      // input.addEventListener('change', () => {
      //   console.log(input.files[0])
      //   readXlsxFile(readFil'../DB_ELECTRA_V1.xlsx', {schema: schema, sheet: "web_bona"}).then(({ rows, errors }) => {
      //     // `errors` list items have shape: `{ row, column, error, value }`.
      //     console.log(errors)
      //     _this.technologies.push(...rows)
      //   }).catch(err => {console.log(err)} )
      // })

    }
  },
  computed:{
    filteredTechnologies(){
      return this.technologies.filter(tech => {
        if(tech.electra !== 1) return false
        return tech.Name.toLowerCase().includes(this.filterSelection.name.toLowerCase())
      }).sort((a,b) => {
        if(a.Name < b.Name) return -1
        if(a.Name > b.Name) return 1
        return 0
      })
    }
  },
  data: function (){
    return {
      moreInfoShow: [],
      filterSelection: {
        waterOrigin:[],
        trl: 1,
        lifespan: 7,
        name: ""
      },
      filterData: {
        waterOrigin: {
          options: [
            { text: 'Groundwater', value: 'GROUNDWATER' },
            { text: 'Wastewater', value: 'WASTEWATER' },
            { text: 'Soil & sediments', value: 'SOIL AND SEDIMENTS' },
            { text: 'Soils', value: 'SOILS' },
          ]
        },
        trl: {
          options: [
            { text: '1', value: '1'},
            { text: '2', value: '2'},
            { text: '3', value: '3'},
            { text: '4', value: '4'},
            { text: '5', value: '5'},
            { text: '6', value: '6'},
            { text: '7', value: '7'},
            { text: '8', value: '8'},
            { text: '9', value: '9'},
            { text: '10', value: '10'},
          ]
        }
      },
      excelSchema: {
        'Code': { prop: 'code', type: String },
        'Name': { prop: 'name', type: String },
        'Description': { prop: 'description', type: String },
        'Partner': { prop: 'partner', type: String },
        'Mail': { prop: 'email', type: String },
        'TRL': { prop: 'trl' },
        'Expected life span': { prop: 'lifespan' },
        'CAPEX': { prop: 'capex' },
        'OPEX': { prop: 'opex' },
        'Treatment capacity (m3/a)': { prop: 'capacity' },
        'Cost (€/m3)': { prop: 'cost' },
        'Target compound': { prop: 'compounds', type: String },
        'Efficiency': { prop: 'efficiency' },
        'cost_energ kwh/a': { prop: 'energy_cost' },
        'water_cons kg/a': { prop: 'water_cons' },
        'ORIGEN AIGUA': { prop: 'water_origin', type: String },
        'Ambiental': { prop: 'environmental' },
        'Economic': { prop: 'economic' },
        'Technologic': { prop: 'technologic' },
        'CODI IMATGE': { prop: 'image', type: String },
        // 'Code': { prop: 'code', type: String },
        // 'Name': { prop: 'name', type: String },
        // 'Description': { prop: 'description', type: String },
        // 'Partner': { prop: 'partner', type: String },
        // 'Mail': { prop: 'email', type: String },
        // 'TRL': { prop: 'trl', type: Number },
        // 'Expected life span': { prop: 'lifespan', type: Number },
        // 'CAPEX': { prop: 'capex', type: Number },
        // 'OPEX': { prop: 'opex', type: Number },
        // 'Treatment capacity (m3/a)': { prop: 'capacity', type: Number },
        // 'Cost (€/m3)': { prop: 'cost', type: Number },
        // 'Target compound': { prop: 'compounds', type: String },
        // 'Efficiency': { prop: 'efficiency', type: Number },
        // 'cost_energ kwh/a': { prop: 'energy_cost', type: Number },
        // 'water_cons kg/a': { prop: 'water_cons', type: Number },
        // 'ORIGEN AIGUA': { prop: 'water_origin', type: String },
        // 'Ambiental': { prop: 'environmental', type: Number },
        // 'Economic': { prop: 'economic', type: Number },
        // 'Technologic': { prop: 'technologic', type: Number },
        // 'CODI IMATGE': { prop: 'image', type: String },
      },

      technologies: []
    }
  }
}
</script>
