<style scoped xmlns="http://www.w3.org/1999/html">
  #lead{
    background-image: url("/images/fons-header-1.jpg");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 250px;
  }
  @media (min-width: 1200px) {
    .bd-sidebar {
      -ms-flex: 0 1 320px;
      flex: 0 1 320px;
    }
  }
  @media (min-width: 768px) {
    @supports ((position:-webkit-sticky) or (position:sticky)){
      .bd-sidebar {
        position: -webkit-sticky;
        position: sticky;
        top: 0rem;
        z-index: 1000;
        height: calc(100vh - 4rem);
      }
    }
    .bd-sidebar {
      border-right: 1px solid rgba(0,0,0,.1);
    }
  }
  .bd-sidebar {
    -ms-flex-order: 0;
    order: 0;
    /*border-bottom: 1px solid rgba(0,0,0,.1);*/
    color: #173c83;
  }

  .pieces{
    width: 5px;
    border-color: #173c83;
    height: 100%;
    min-width: 12px;
  }

  .grey-piece{
    background-color:  gray;
  }
  .green-piece{
    background-color:  green;
  }

  .not-value{
    background-color: rgba(128, 128, 128, 0.51);
    height: 20% !important;
  }

  .blue-piece{
    background-color:  #173c83;
  }
  .red-piece{
    background-color: darkred;
  }


</style>

<style>
.btn input[type="radio" i]{
  display: none !important;
}
</style>

<template>
  <div>
    <div id="lead">
      <b-container style="min-height: 250px">
        <b-row style="min-height: 250px" class="h-100 pt-2 pb-3 align-items-center">
          <b-col cols="12" class="text-white text-center">
            <h2> <b>EDSS</b></h2>
            <p>Find you most suitable bioremediation TECHNOLOGY</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
<!--/*    <b-container fluid style="width: 90%">*/-->
    <b-container>
      <b-row class="flex-xl-nowrap">
        <b-col cols="3" class="bd-sidebar justify-content-start">
          <div>
            <h3 class="pt-3">Filters</h3>
            <h5 class="border-bottom pb-1">Contaminated matrix</h5>
            <b-form-group class="pb-2">
              <b-form-checkbox-group
                  v-model="filterSelection.waterOrigin"
                  :options="filterData.waterOrigin.options"
                  name="waterOrigin"
                  plain
                  stacked
              ></b-form-checkbox-group>
            </b-form-group>
            <h5 class="border-bottom pb-1">Target pollutants</h5>
            <b-form-group >
              <b-form-checkbox-group
                v-model="filterSelection.pollutants"
                :options="filterData.pollutants.options"
                name="pollutants"
                plain
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
            <b-row>
              <b-col>
                <h5 class="border-bottom pb-1 mt-2">TRL <a v-b-modal:id="'trl-pop'"><b-icon-question-circle></b-icon-question-circle></a></h5>
                <b-modal id="trl-pop"  ok-only hide-header centered >
                  <p>
                    Technology readiness levels (TRL): Where a topic description refers to a TRL, the following
                    definitions apply, unless otherwise specified (Extract from Part 19 - Commission Decision
                    C(2014)4995)
                  </p>
                  <ul>
                    <li>TRL 1 – basic principles observed</li>
                    <li>TRL 2 – technology concept formulated</li>
                    <li>TRL 3 – experimental proof of concept</li>
                    <li>TRL 4 – technology validated in lab</li>
                    <li>TRL 5 – technology validated in relevant environment (industrially relevant environment in the
                      case of key enabling technologies)</li>
                    <li>TRL 6 – technology demonstrated in relevant environment (industrially relevant environment in
                      the case of key enabling technologies)</li>
                    <li>TRL 7 – system prototype demonstration in operational environment</li>
                    <li>TRL 8 – system complete and qualified</li>
                    <li>TRL 9 – actual system proven in operational environment (competitive manufacturing in the case
                      of key enabling technologies; or in space)</li>
                  </ul>
                </b-modal>
                <b-form-checkbox-group
                  v-model="filterSelection.trl"
                  :options="filterData.trl.options"
                  name="trl"
                  plain
                  stacked
                ></b-form-checkbox-group>
<!--                <b-form-group>-->
<!--                  <b-form-radio-group-->
<!--                      class="w-100"-->
<!--                      v-model="filterSelection.trl"-->
<!--                      :options="filterData.trl.options"-->
<!--                      button-variant="outline-primary"-->
<!--                      name="trl"-->
<!--                      :buttons = "true"-->
<!--                      plain-->
<!--                      size="sm"-->
<!--                  ></b-form-radio-group>-->
<!--                </b-form-group>-->
              </b-col>

            </b-row>
<!--            <h5 class="border-bottom pb-1 mt-4">Expected life span</h5>-->
<!--            <b-form-input style="width: 100%" v-model="filterSelection.lifespan" type="range" min="1" max="10" step="1"></b-form-input>-->
<!--            <h3 class="mt-4">Sort by</h3>
            <h5 class="border-bottom pb-1 mt-1">Environmental <a v-b-modal:id="'environmental-pop'"><b-icon-question-circle></b-icon-question-circle></a></h5>
            <b-modal id="environmental-pop"  ok-only hide-header centered >
              <p>This indicator aggregates removal efficiencies, water consumption and
                the annual energy cost</p>
            </b-modal>
            <b-form-group>
              <b-form-radio-group
                  class="w-100"
                  v-model="filterSelection.enviormental"
                  :options="filterData.sorter.options"
                  button-variant="outline-primary"
                  name="trl"
                  :buttons = "true"
                  plain
                  size="sm"
              ></b-form-radio-group>
            </b-form-group>
            <h5 class="border-bottom pb-1 mt-2">Economic <a v-b-modal:id="'economic-pop'"><b-icon-question-circle></b-icon-question-circle></a></h5>
            <b-modal id="economic-pop"  ok-only hide-header centered >
              <p>
                This indicator aggregates technical expenses linked to CAPEX and OPEX,
                as well as the overall cost per treated cubic metre.
              </p>
            </b-modal>
            <b-form-group>
              <b-form-radio-group
                  class="w-100"
                  v-model="filterSelection.economic"
                  :options="filterData.sorter.options"
                  button-variant="outline-primary"
                  name="trl"
                  :buttons = "true"
                  plain
                  size="sm"
              ></b-form-radio-group>
            </b-form-group>
            <h5 class="border-bottom pb-1 mt-2">Technological <a v-b-modal:id="'technological-pop'"><b-icon-question-circle></b-icon-question-circle></a></h5>
            <b-modal id="technological-pop"  ok-only hide-header centered >
              <p>
                This indicator aggregates the TRL, the expected life span, treatment
                capacity, the origin of water and the target compounds.
              </p>
            </b-modal>
            <b-form-group>
              <b-form-radio-group
                  class="w-100"
                  v-model="filterSelection.technologic"
                  :options="filterData.sorter.options"
                  button-variant="outline-primary"
                  name="trl"
                  :buttons = "true"
                  plain
                  size="sm"
              ></b-form-radio-group>
            </b-form-group>-->
          </div>
        </b-col>
        <b-col cols="9">
          <b-modal id="rating-pop" size="lg" ok-only hide-header centered >
            <h2>Technology rating</h2>
            <p>
              <b>Technology</b> Bars are based to TRL indicator. The higher the TRL, the higher the bar.
            </p>
            <p>
              <b>Envirormental</b> Derived from comparison to benchmark
            </p>
            <b-table-simple bordered>
              <tbody>
              <tr>
                <td colspan="10" class="text-center" >
                  <span >
                    Relative Impact of treatment to benchmark
                    <br/>[Technology impact per m<sup>3</sup> / Benchmark Technology impact per m<sup>3</sup>]
                  </span>
                </td>
              </tr>
              <tr>
                <td class="text-center"> <span>n.a.</span> </td>
                <td class="text-center"> <span>1</span> </td>
                <td class="text-center"> <span>2</span> </td>
                <td class="text-center"> <span>3</span> </td>
                <td class="text-center"> <span>4</span> </td>
                <td class="text-center"> <span>5 <b>Benchmark</b></span> </td>
                <td class="text-center"> <span>6</span> </td>
                <td class="text-center"> <span>7</span> </td>
                <td class="text-center"> <span>8</span> </td>
                <td class="text-center"> <span>9</span> </td>
              </tr>
              <tr>
                <td class="text-center"> <span> - </span> </td>
                <td class="text-center"> <span> &gt;15 </span> </td>
                <td class="text-center"> <span> &gt;7-15 </span> </td>
                <td class="text-center"> <span> &gt;3-7.0 </span> </td>
                <td class="text-center"> <span> &gt;1.2-3 </span> </td>
                <td class="text-center"> <span> 0.8 – 1.2 </span> </td>
                <td class="text-center"> <span> 0.5 - &lt;0.8 </span> </td>
                <td class="text-center"> <span> 0.3 - &lt;0.5 </span> </td>
                <td class="text-center"> <span> 0.1- &lt;0.3 </span> </td>
                <td class="text-center"> <span> &lt;0.1 </span> </td>
              </tr>
              </tbody>
            </b-table-simple>
            <p>
              <b>Economic</b> Derived from comparison to benchmark
              <b-table-simple bordered>
                <tbody>
                <tr>
                  <td colspan="10" class="text-center" >
                  <span >
                    Relative cost of treatment to benchmark
                    <br/>[Technology costs per m<sup>3</sup> / Benchmark Technology per m<sup>3</sup>]
                  </span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center"> <span>n.a.</span> </td>
                  <td class="text-center"> <span>1</span> </td>
                  <td class="text-center"> <span>2</span> </td>
                  <td class="text-center"> <span>3</span> </td>
                  <td class="text-center"> <span>4</span> </td>
                  <td class="text-center"> <span>5 <b>Benchmark</b></span> </td>
                  <td class="text-center"> <span>6</span> </td>
                  <td class="text-center"> <span>7</span> </td>
                  <td class="text-center"> <span>8</span> </td>
                  <td class="text-center"> <span>9</span> </td>
                </tr>
                <tr>
                  <td class="text-center"> <span> - </span> </td>
                  <td class="text-center"> <span> &gt;50.0 </span> </td>
                  <td class="text-center"> <span> &gt;10.0-50.0 </span> </td>
                  <td class="text-center"> <span> &gt;3-10.0 </span> </td>
                  <td class="text-center"> <span> &gt;1.2-3 </span> </td>
                  <td class="text-center"> <span> 0.8 – 1.2 </span> </td>
                  <td class="text-center"> <span> 0.5 - &lt;0.8 </span> </td>
                  <td class="text-center"> <span> 0.3 - &lt;0.5 </span> </td>
                  <td class="text-center"> <span> 0.1- &lt;0.3 </span> </td>
                  <td class="text-center"> <span> &lt;0.1 </span> </td>
                </tr>
                </tbody>
              </b-table-simple>
            </p>

          </b-modal>
          <b-row v-for="(tech, index) in filteredTechs" :key = "index" >
            <b-col cols="8" class="border-bottom pe-0 pb-3">
              <b-row><b-col class="d-inline-flex pt-2">
                <b-img-lazy class="me-1" v-if="tech.electra === 1" style="max-height: 40px" fluid :src="'/images/logo.jpg'">  </b-img-lazy>
                <h3 class="bold pt-2">
                  {{tech.Name}}
                </h3>
              </b-col></b-row>
              <b-row><b-col><p style="text-align: justify">{{tech.Description}}</p></b-col></b-row>
              <b-row>
                <b-col cols="7">
                  <b-img-lazy v-if="tech['CODI IMATGE']" center style="max-height: 200px" fluid :src="'/figures/'+tech['CODI IMATGE']"></b-img-lazy>
                  <b-img-lazy v-else center style="max-height: 200px" fluid :src="'/images/ezgif.com-gif-maker.jpeg'"></b-img-lazy>
                </b-col>
                <b-col>
                  <b-row> <b-col cols="2">Partner:</b-col> <b-col class="text-end">{{tech.Partner}}</b-col> </b-row>
                  <b-row> <b-col class="col-3">Mail:</b-col> <b-col style="font-size: 0.1rem"  class=" text-end mailtext">{{tech.Mail}}</b-col> </b-row>
                  <b-row> <b-col class="col-12"><b>Technology rating</b> <a v-b-modal:id="'rating-pop'"><b-icon-question-circle></b-icon-question-circle></a> </b-col></b-row>

                  <b-row>
                    <b-col cols="3">
                      <b-img fluid src="images/icon-water.svg"></b-img>
                    </b-col>
                    <b-col class="p-0">
                      <b-row style="height: 100%" class="pt-1 pb-1">
                        <b-col v-if="tech.TRL" style="display: flex; justify-content: space-evenly; align-items: stretch">
                          <div v-for="i in 9" class="pieces border-3" :class="pieceColor(i, tech.TRL)"></div>
                        </b-col>
                        <b-col v-else style="display: flex; justify-content: space-evenly; align-items: stretch">
                          <div class="mt-2"><span>Not defined</span></div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1" class="flex align-self-center me-3">
                      <a @click="toogleMoreInfo(tech.Code, 'tech_'+tech.Code)"><b-icon-plus-circle class=""></b-icon-plus-circle></a>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="3">
                      <b-img fluid src="images/icon-earth.svg"></b-img>
                    </b-col>
                    <b-col class="p-0">
                      <b-row style="height: 100%" class="pt-1 pb-1">
                        <b-col v-if="tech.Ambiental" style="display: flex; justify-content: space-evenly; align-items: stretch">
                          <div v-for="i in 9" class="pieces border-3" :class="pieceColor(i, tech.Ambiental)"></div>
                        </b-col>
                        <b-col v-else style="display: flex; justify-content: space-evenly; align-items: stretch">
                          <div class="mt-2"><span>Not defined</span></div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1" class="flex align-self-center me-3">
                      <a @click="toogleMoreInfo(tech.Code, 'earth_'+tech.Code)"><b-icon-plus-circle class=""></b-icon-plus-circle></a>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="3">
                      <b-img fluid src="images/icon-money.svg"></b-img>
                    </b-col>
                    <b-col class="p-0">
                      <b-row style="height: 100%" class="pt-1 pb-1">
                        <b-col v-if="tech.Economic" style="display: flex; justify-content: space-evenly; align-items: stretch">
                          <div v-for="i in 9" class="pieces border-3" :class="pieceColor(i, tech.Economic)"></div>
                        </b-col>
                        <b-col v-else style="display: flex; justify-content: space-evenly; align-items: stretch">
                          <div class="mt-2"><span>Not defined</span></div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1" class="flex align-self-center me-3">
                      <a @click="toogleMoreInfo(tech.Code, 'money_'+tech.Code)"><b-icon-plus-circle class=""></b-icon-plus-circle></a>
                    </b-col>
                  </b-row>

                </b-col>
              </b-row>

            </b-col>
            <b-col cols="4">
              <div :hidden="!moreInfoShow.includes('earth_'+tech.Code)" :id="tech.Code+' earth_'+tech.Code" class="h-100">
                <div style="background-color: #f2f2f2; height: 96%" class="p-2 m-3 me-0 mb-4">
                  <p class="text-center"><b>ENVIRONMENTAL </b><br></p>
<!--                  <p><b>Environmental impact of Benchmark: </b><span>{{arrodonir(tech['Environmental impact [ILCD mPt/m3] Benchmark'],2)}} mPt/m<sup>3</sup></span></p>-->
                  <p><b>Environmental impact of technology assessed in Electra: : </b><span>{{ arrodonir(tech['Environmental impact [ILCD mPt/m3]'], 2)}} mPt/m<sup>3</sup> </span></p>
                  <p><b>Relevant processes contributing to environmental impact technology: </b><span>{{tech['Environmentally relevant for technology']}}</span></p>
                </div>
              </div>
              <div :hidden="!moreInfoShow.includes('money_'+tech.Code)" :id="tech.Code+' money_'+tech.Code" class="h-100">
                <div style="background-color: #f2f2f2; height: 96%" class="p-2 m-3 me-0 mb-4">
                  <p class="text-center"><b>ECONOMIC </b><br></p>
                  <p><b>Treatment capacity operated in Electra: </b><span>{{ arrodonir(tech['Treatment capacity (m3/a)'], 2) }} m<sup>3</sup>/y</span></p>
                  <p><b>Treatment costs: </b><span>{{arrodonir(tech['Cost (€/m3)'],2)}} €/m<sup>3</sup></span></p>
                  <p><b>Benchmark technology: </b><span>{{tech['Benchmark technology']}} </span></p>
<!--                  <p><b>Treatment costs of benchmark technology: </b><span>{{ arrodonir(tech['Cost Benchmark [EUR/m3]'], 2)}} €/m<sup>3</sup></span></p>-->
                </div>
              </div>
              <div :hidden="!moreInfoShow.includes('tech_'+tech.Code)" :id="tech.Code+' tech_'+tech.Code" class="h-100">
                <div style="background-color: #f2f2f2; height: 96%" class="p-2 m-3 me-0 mb-4">
                  <p class="text-center"><b>TECHNOLOGY</b><br></p>
                  <b>TRL: </b><span class="">{{tech.TRL}}</span><br>
                  <b>Scale: </b><span>{{tech['Scale']}} </span><br>
                  <b>Treatment capacity: </b><span>{{arrodonir(tech['Treatment capacity (m3/a)'], 2)}} m<sup>3</sup>/y</span><br>
                  <b>Target compounds: </b><span>{{tech['TARGET COMPOUND']}}</span><br>
                  <b>Efficiency: </b><span>{{tech['EFFICIENCY']}}</span><br>
                  <b>Benchmark technology: </b><span>{{tech['Benchmark technology']}}</span><br>
                  <b>Water origin: </b><span>{{tech['ORIGEN AIGUA']}}</span><br>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

/*!
* FitText.js 1.0 jQuery free version
*
* Copyright 2011, Dave Rupert http://daverupert.com
* Released under the WTFPL license
* http://sam.zoy.org/wtfpl/
* Modified by Slawomir Kolodziej http://slawekk.info
*
* Date: Tue Aug 09 2011 10:45:54 GMT+0200 (CEST)
*/

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  computed: {
    filteredTechs() {
      let auxTechs = [...this.technologies];
      //Filter technologies that Code not starts with 'B'
      auxTechs = auxTechs.filter(tech => !tech.Code.startsWith('B'));
      if(this.filterSelection.waterOrigin.length >0){
        auxTechs = auxTechs.filter(t => {
          return this.filterSelection.waterOrigin.map(a => a.toLowerCase()).includes(t['ORIGEN AIGUA'].toLowerCase())
        })
      }
      auxTechs = auxTechs.filter(t => {
        if (this.filterSelection.trl.length > 0) {
          if (this.filterSelection.trl.includes(1) && [1,2,3].includes(t.TRL)) return true;
          if (this.filterSelection.trl.includes(4) && [4,5,6].includes(t.TRL)) return true;
          if (this.filterSelection.trl.includes(7) && [7,8,9].includes(t.TRL)) return true;
          return false;
        } else {
          return true
        }
      })
      if(this.filterSelection.pollutants.length >0) {
        auxTechs = auxTechs.filter(t => {
          console.log(t['FAMILY COMPOUND'])
          if(!t['FAMILY COMPOUND']) return false;
          else{
            let aux = t['FAMILY COMPOUND'];
            console.log(aux, this.filterSelection.pollutants)
            return this.filterSelection.pollutants.includes(aux);
          }
        })
      }

      auxTechs.sort((tech1, tech2) => {
        let trl1 = 0;
        let trl2 = 0;
        if (tech1.TRL) trl1 = tech1.TRL;
        if (tech2.TRL) trl2 = tech2.TRL;
        return trl2 - trl1;
      });

      // console.log("launch sort")
      // auxTechs.sort((tech1, tech2) => {
      //   let val1 = 0
      //   if(!isNaN(Number(tech1.Ambiental)))   val1 += tech1.Ambiental * this.filterSelection.enviormental
      //   if(!isNaN(Number(tech1.Economic)))    val1 += tech1.Economic * this.filterSelection.economic
      //   if(!isNaN(Number(tech1.Technologic))) val1 += tech1.Technologic * this.filterSelection.technologic
      //
      //   let val2 = 0
      //   if(!isNaN(Number(tech2.Ambiental)))   val2 += tech2.Ambiental * this.filterSelection.enviormental
      //   if(!isNaN(Number(tech2.Economic)))    val2 += tech2.Economic * this.filterSelection.economic
      //   if(!isNaN(Number(tech2.Technologic))) val2 += tech2.Technologic * this.filterSelection.technologic
      //
      //   //Print console detailed debug the sorter
      //   // console.log(tech1.Code + ": " + val1, tech1.Ambiental * this.filterSelection.enviormental, tech1.Economic * this.filterSelection.economic, tech1.Technologic * this.filterSelection.technologic)
      //
      //   return val2 - val1
      // });

      return auxTechs
    }
  },
  mounted() {
    this.getData()
    this.test()
  },
  updated() {
    let els = document.getElementsByClassName('mailtext')
    for (let i = 0; i < els.length; i++) {
      fitText(els[i], 1.5);
    }
  },
  methods:{
    arrodonir(num, decimals) {
      if (isNaN(num)) return num;
      return parseFloat(num).toFixed(decimals);
    },
    test() {

      var addEvent = function (el, type, fn) {
        if (el.addEventListener)
          el.addEventListener(type, fn, false);
        else
          el.attachEvent('on'+type, fn);
      };

      var extend = function(obj,ext){
        for(var key in ext)
          if(ext.hasOwnProperty(key))
            obj[key] = ext[key];
        return obj;
      };

      window.fitText = function (el, kompressor, options) {

        var settings = extend({
          'minFontSize' : -1/0,
          'maxFontSize' : 1/0
        },options);

        var fit = function (el) {
          var compressor = kompressor || 1;

          var resizer = function () {
            // Set the font size with the compressor as the basis
            // Debug the compressor
            console.log(el);
            el.style.fontSize = Math.max(Math.min(el.clientWidth / (compressor*10), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize)) + 'px';
          };

          // Call once to set.
          resizer();

          // Bind events
          // If you have any js library which support Events, replace this part
          // and remove addEvent function (or use original jQuery version)
          addEvent(window, 'resize', resizer);
          addEvent(window, 'orientationchange', resizer);
        };

        if (el.length)
          for(var i=0; i<el.length; i++)
            fit(el[i]);
        else
          fit(el);

        // return set of elements
        return el;
      };
    },
    pieceColor(pos, tech){
      if(pos-1 >= (tech)) {return 'grey-piece'}
      else {return 'blue-piece'}
    },
    getData(){
      let url = '/DB_ELECTRA_V4.xlsx'
      let oReq = new XMLHttpRequest()
      let _this = this
      oReq.open("GET", url, true)
      oReq.responseType = "arraybuffer"
      oReq.onload = function (e) {
        let arraybuffer = oReq.response;

        /* convert data to binary string */
        let data = new Uint8Array(arraybuffer);
        let arr = new Array();
        for(let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join("");

        /* Call XLSX */
        var workbook = XLSX.read(bstr, {type:"binary"});
        /* DO SOMETHING WITH workbook HERE */
        var first_sheet_name = workbook.SheetNames[0];
        /* Get worksheet */
        var worksheet = workbook.Sheets[first_sheet_name];
        _this.technologies = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      }
      oReq.send();
      // input.addEventListener('change', () => {
      //   console.log(input.files[0])
      //   readXlsxFile(readFil'../DB_ELECTRA_V1.xlsx', {schema: schema, sheet: "web_bona"}).then(({ rows, errors }) => {
      //     // `errors` list items have shape: `{ row, column, error, value }`.
      //     console.log(errors)
      //     _this.technologies.push(...rows)
      //   }).catch(err => {console.log(err)} )
      // })

    },
    toogleMoreInfo(techCode, zone){
      if(this.moreInfoShow.includes(zone)) this.moreInfoShow = this.moreInfoShow.filter(e => e !== zone);
      else{
        this.moreInfoShow = this.moreInfoShow.filter(e => !e.includes(techCode));
        this.moreInfoShow.push(zone)
      }
    }
  },
  data: function (){
    return {
      moreInfoShow: [],
      filterSelection: {
        waterOrigin:[],
        pollutants:[],
        trl: [1,4,7],
        lifespan: 7,
        enviormental: 3,
        economic: 3,
        technologic: 3
      },
      filterData: {
        waterOrigin: {
          options: [
            { text: 'Groundwater', value: 'GROUNDWATER' },
            { text: 'Wastewater', value: 'WASTEWATER' },
            { text: 'Soil & sediments', value: 'SOIL & SEDIMENTS' },
            // { text: 'Soils', value: 'SOILS' },
          ]
        },
        pollutants: {
          options: [
            { text: 'Nutriens', value: 'Nutrients' },
            { text: 'Metals', value: 'Metals' },
            { text: 'Hydrocarbons', value: 'Hydrocarbons' },
            { text: 'Micropollutants', value: 'Organic micropollutants' },
          ]
        },
        trl: {
          options: [
            { text: '1-3', value: 1 },
            { text: '4-6', value: 4 },
            { text: '7-9', value: 7 }
          ]
        },
        sorter: {
          options: [
            { text: '0', value: 0},
            { text: '1', value: 1},
            { text: '2', value: 2},
            { text: '3', value: 3},
            { text: '4', value: 4},
            { text: '5', value: 5},
          ]
        }
      },
      excelSchema: {
        'Code': { prop: 'code', type: String },
        'Name': { prop: 'name', type: String },
        'Description': { prop: 'description', type: String },
        'Partner': { prop: 'partner', type: String },
        'Mail': { prop: 'email', type: String },
        'TRL': { prop: 'trl', type: Number },
        'Expected life span': { prop: 'lifespan' },
        'CAPEX': { prop: 'capex' },
        'OPEX': { prop: 'opex' },
        'Treatment capacity (m3/a)': { prop: 'capacity' },
        'Cost (€/m3)': { prop: 'cost' },
        'Target compound': { prop: 'compounds', type: String },
        'Efficiency': { prop: 'efficiency' },
        'cost_energ kwh/a': { prop: 'energy_cost' },
        'water_cons kg/a': { prop: 'water_cons' },
        'ORIGEN AIGUA': { prop: 'water_origin', type: String },
        'Ambiental': { prop: 'environmental' },
        'Economic': { prop: 'economic' },
        'Technologic': { prop: 'technologic' },
        'CODI IMATGE': { prop: 'image', type: String },
        'Family compound': { prop: 'family_compound', type: String },
        'electra': { prop: 'electra_technology' },
        // 'Code': { prop: 'code', type: String },
        // 'Name': { prop: 'name', type: String },
        // 'Description': { prop: 'description', type: String },
        // 'Partner': { prop: 'partner', type: String },
        // 'Mail': { prop: 'email', type: String },
        // 'TRL': { prop: 'trl', type: Number },
        // 'Expected life span': { prop: 'lifespan', type: Number },
        // 'CAPEX': { prop: 'capex', type: Number },
        // 'OPEX': { prop: 'opex', type: Number },
        // 'Treatment capacity (m3/a)': { prop: 'capacity', type: Number },
        // 'Cost (€/m3)': { prop: 'cost', type: Number },
        // 'Target compound': { prop: 'compounds', type: String },
        // 'Efficiency': { prop: 'efficiency', type: Number },
        // 'cost_energ kwh/a': { prop: 'energy_cost', type: Number },
        // 'water_cons kg/a': { prop: 'water_cons', type: Number },
        // 'ORIGEN AIGUA': { prop: 'water_origin', type: String },
        // 'Ambiental': { prop: 'environmental', type: Number },
        // 'Economic': { prop: 'economic', type: Number },
        // 'Technologic': { prop: 'technologic', type: Number },
        // 'CODI IMATGE': { prop: 'image', type: String },
      },

      technologies: []
    }
  }
}
</script>
